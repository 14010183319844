
.oval-radius {
    border-radius: 35px;
}
.banner {
    color: #E9E9E9;
}
.btn-black:hover, .btn-black:active, .btn-black:focus, .no-outline, .no-outline:focus, .no-outline:active {
    box-shadow: none !important;
    outline: 0 !important;
}
.btn-black {
    background: #000 !important;
}
.radius-10 {
    border-radius : 10px !important;
}
.heading-title {
    line-height: 1.4;
}
.front-view {
    height: calc(100vh - 72px);
}
.component-title {
    color: #E9E9E9;
    font-size: 110px;
    line-height: 150px;
}
.component-desc {
    font-size: 35px;
    line-height: 42px;
}
.data-list li{
    font-size: 20px;
    font-weight: 400;
    border-left: 2px solid #00BFAC;
    line-height: 32px;
    color: #211E1E;
}
.vision-count {
    border: 2px solid #00000014;
    border-radius: 35px;
    box-shadow: -2px -2px 8px #00000014;
}
.vision-count h4 {
    font-size: 28px;
}
.vision-count h2 {
    font-size: 40px;
}
.solution-list .nav-item.nav-link {
    border: 1px solid #00BFAC !important;
    border-radius: 20px;
    color: #211E1E !important;
    font-size: 18px;
}
.solution-list .nav-item.nav-link.active {
    background: #00BFAC;
    color: #fff !important;
    font-weight: 600;
}
.gradient-img {
    margin-top: -190px;
}
.thumb-radius {
    min-height: 200px;
}
.thumb-radius, .thumb-radius img {
    border-radius: 30px;
}
.large-thumb {
    margin-left: -30px;
}
.right-thmb-wrapper {
    width: 36%;
}
.center-testi-section {
    width: 28%;
    right: 33%;
    top: -5%;
    z-index: 9;
    border-radius: 30px;
}
.video-play-btn {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 90px;
    width: 90px;
}
.center-thumb {
    height: 350px;
}
.top-thumb-m {
    margin-top: -50px;
}
.thumb-radius:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.1);
    height: 100%;
    width: 100%;
    margin: auto;
    border-radius: 30px;
}
.small-right-seciton {
    right: 0;
    width: 370px;
    top: -50px;
}
.small-right-seciton .small-video{
    width: 270px;
    height: 330px;
}
.form-input-border {
    border: 1px solid #707070 !important;
}
.form-textarea {
    height: 150px !important;
}
.estrick-area {
    top: 0;
    right: 0;
}
.team-wrapper {
    border-radius: 15px;
}
.team-wrapper .team-user-img {
    width: 80%;
}
.team-wrapper {
    width: 30%;
}
.mbl-header .slide-pane__content {
    padding: 0;
}
.mbl-header .slide-pane__close {
    margin: 0;
}
.mbl-header {
    width: 70% !important;
}
.mbl-header .slide-pane__header {
    background: #fff;
    border: 0;
}
.btn.slide-btn {
    top: 0;
    bottom: 0;
    height: 50px;
    margin: auto;
    border-radius: 100%;
    padding: 7px 8px;
    width: 50px;
    z-index: 99;
}
.btn.slide-btn.slide-left {
    left: -50px;
}
.btn.slide-btn.slide-right {
    right: -50px;
}
.btn.slide-btn img {
    height: 60px;
}
.after-section {
    z-index: 9;
    margin-top: 55%;
    position: relative;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    -webkit-transition : opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
    transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}
#testimonial {
    -webkit-transition: background-color 1s ease-in;
            transition: background-color 1s ease-in;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
}
#testimonial:hover {
    background-color: #211E1E;
}
.video-close {
    top: 0;
    right: -40px;
}
.video-modal-body {
    max-height: 445px;
}

@media screen and (max-width: 991px) {
    .front-view {
        height: 100%;
        bottom: 0;
        top: 80px;
        left: 0;
        right: 0;
    }
    .after-section {
        margin-top: 130%;
    }
    .top-agro--wrapper img {
        width: 100%;
        height: 100%;
    }
    .top-agro--wrapper .gradient-img {
        margin-top: -270px;
    }
    .component-title {
        font-size: 80px;
        line-height: 120px;
    }
    .component-desc {
        font-size: 28px;
        line-height: 32px;
    }
    .member--name {
        min-height: 100px;
    }
    #testimonial {
        background-color: #211E1E;
    }
    .btn.slide-btn.slide-left {
        left: -20px;
    }
    .btn.slide-btn.slide-right {
        right: -10px;
    }
}

@media screen and (max-width: 767px) {

    .btn.slide-btn {
        display: none;
    }
    .after-section {
        margin-top: 160%;
    }

    .heading-title {
        font-size: 28px;
    }
    .top-agro--wrapper .gradient-img {
        margin-top: -120px;
    }
    .component-title {
        font-size: 40px;
        line-height: 60px;
    }
    .component-desc {
        font-size: 20px;
        line-height: 24px;
    }
    .large-thumb {
        margin-left: 0;
    }
    .center-testi-section {
        width: 40%;
        top: -10%;
        right: 20%;
    }
    .right-thmb-wrapper {
        width: 42%;
    }
    .center-thumb {
        height: auto;
    }
    .top-thumb-m {
        margin-top: 10px;
    }
    .thumb-radius {
        min-height: 150px;
    }
    .video-play-btn img {
        width: 50px;
    }
    .team-wrapper {
        width: 100%;
    }
    .member--name {
        min-height: auto;
    }
    .form-submit-btn {
        width: 100%;
    }
    
}


