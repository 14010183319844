
@font-face {
    font-family: 'Roboto-light';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Light.ttf) format('opentype');
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('opentype');
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Black.ttf) format('opentype');
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Medium.ttf) format('opentype');
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./fonts/montserrat/Montserrat-Light.otf) format('opentype');
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./fonts/montserrat/Montserrat-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./fonts/montserrat/Montserrat-Medium.otf) format('opentype');
}

body, html {
    font-size: 1rem;
    height: 100%;
    margin: 0;
    font-family: 'Montserrat';
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.btn, .modal, .base-font {
    font-size: 14px;
}
.no-underline, .no-underline:hover {
    text-decoration: none !important;
}
.header-link {
    font-size: 1.10rem;
    font-family: 'Roboto';
}
.header-link:hover, .header-link:active, .header-link:focus {
    color: #00F076 !important;
}
.bg-dark {
    background-color: #211E1E !important;
}
.bg-titan{
    background-color: #EFEEFF !important;
}
.bg-teal {
    background-color:#00887A !important;
}
.text-black {
    color: #211E1E !important;
}
.text-teal {
    color: #00887A !important;
}
.text-gray-dark {
    color: #525252 !important;
}
.text-gray-light {
    color: #7C8187 !important;
}
.text-green-light {
    color: #00BFAC !important;
}
.box-shadow {
    box-shadow: -2px -2px 8px #00000014 !important;
}
.main-header {
    min-height: 77px;
}
.form-submit-btn {
    font-size: 19px;
    padding: 10px 90px;
}
.ReactModalPortal {
    position: relative;
    z-index: 9999;
}

.dashed-border {
    background-image: linear-gradient(#00bfac 45%, rgba(255,255,255,0) 0%);
    background-position: center;
    background-size: 2px 37px;
    background-repeat: repeat-y;
}

.dashed-border::after {
    content: "";
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    background: #00bfac;
    border-radius: 100%;
    top: 0;
    left: 0;
    margin: auto;
}
.last-filled-circle::after {
    top: auto;
    bottom: 0;
}



@media screen and (max-width: 991px) {

    .header-link {
        font-family: 'Roboto-Light';;
    }
    .kp-img {
        height: 200px !important;
    }

}
@media screen and (max-width: 767px) {

    .dashed-border {
        background-position: left;
    }
    .dashed-border::after {
        right: auto;
        left: -9px;
    }

}

